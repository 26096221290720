// extracted by mini-css-extract-plugin
export var wrapper = "WhatIs-module--wrapper--1lLJU";
export var headerWrapper = "WhatIs-module--headerWrapper--3GTSF";
export var header = "WhatIs-module--header--JHDPW";
export var linesList = "WhatIs-module--linesList--1MieM";
export var lineBlack = "WhatIs-module--lineBlack--fVW6B";
export var lineYellow = "WhatIs-module--lineYellow--2-1kq";
export var linesWrapper = "WhatIs-module--linesWrapper--2D13P";
export var bioWrapper = "WhatIs-module--bioWrapper--1W8fL";
export var bioText = "WhatIs-module--bioText--3s0Fd";
export var sharkManWrapper = "WhatIs-module--sharkManWrapper--yYydp";
export var sharkMan = "WhatIs-module--sharkMan--3b9fl";